import { CommonResourceProps } from 'sensortower-components/src/components/CommonResource/CommonResource';

const createLocalizationResolver = (items: CommonResourceProps[] | undefined) => {
  if (!items || items.length === 0) return {};
  return items.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.key]: curr
    };
  }, {});
};

export default createLocalizationResolver;
