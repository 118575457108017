import React from 'react';
import { CommonResourceProps } from 'sensortower-components/src/components/CommonResource/CommonResource';

import createLocalizationResolver from '../../utils/createLocalizationResolver';

export const LocalizationContext = React.createContext({});

interface Props {
  localizationLookup: CommonResourceProps[] | undefined;
  children: React.ReactElement;
}

export const LocalizationProvider = ({ localizationLookup, children }: Props) => {
  return (
    <LocalizationContext.Provider value={createLocalizationResolver(localizationLookup)}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalizationContext = () =>
  React.useContext<{ [key: string]: CommonResourceProps }>(LocalizationContext);

export default LocalizationContext;
