import React from 'react';
import { join } from 'node:path/posix';
import Bugsnag from '@bugsnag/js';
import { client, parseBooleanEnvVar } from 'sensortower-utils';
import { ContentModuleProvider } from 'sensortower-components/src/base-components/ContentModule/ContentModuleContext';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import { LocalizationProvider } from 'sensortower-components/src/components/LocalizationContext/LocalizationContext';
import contentMapping from 'sensortower-components/src/contentMapping';
import { GetStaticProps } from 'next';
import Mustache from 'mustache';
import handleRedirect from '../src/handleRedirect';
import { fetchArticleData, isAIArticle } from '../src/lib/s3';

const preview = parseBooleanEnvVar(process.env.CONTENTFUL_USE_PREVIEW);
const site = process.env.SITE;
const pagesRevalidate = parseInt(process.env.PAGES_REVALIDATE as string, 10);
const revalidate = !isNaN(pagesRevalidate) ? pagesRevalidate : false;
const stage = process.env.STAGE;

export type PageGetStaticPathsProps = {
  locales: string[];
};

export const getStaticPaths = async ({ locales }: PageGetStaticPathsProps) => {
  const { data } = await client.Paths({ locales, preview, site });

  // Remove all paths for entries of 'Resource' content type.
  // We want resources to show up on the resources index page,
  // but we don't want to generate details page for them.
  // See https://github.com/sensortower-product/website/issues/439.
  const paths = data?.paths?.filter((path) => {
    const slug = path.params?.slug;
    const isResource = slug?.length === 2 && slug?.[0] === 'resources';

    return !isResource && !isAIArticle(slug?.[1]);
  });

  return {
    paths,
    fallback: 'blocking'
  };
};

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
  try {
    const slug = (params?.slug as [string]) || ['/'];
    const path = join('/', slug.join('/'));

    // Workaround for redirects not taken into account in Client side routing
    // Only check for redirects when its fallback
    if (stage !== 'build') {
      // Check if the page is a redirect
      await handleRedirect(path);
      // If it isn't a redirect, check if it is available s3 article and if so, fetch the article data
      try {
        const pageData = await fetchArticleData(path, (template) => Mustache.render(template, { utm_content: slug }));
        if (pageData) {
          return {
            props: { pageData }
          };
        }
      } catch (err: any) {
        console.error(err);
        Bugsnag.notify(err, (event) => {
          event.severity = 'error';
          event.unhandled = true;
        });
      }
    }

    const { data: pageData } = await client.Page({ path, locale, preview, site });
    if (!pageData?.page) {
      console.log('[NotFound][GetStaticProps]', path);
      return {
        notFound: true
      };
    }

    return {
      props: {
        pageData
      },
      revalidate
    };
  } catch (err: any) {
    console.log('[Error][GetStaticProps]', err.name);
    throw err;
  }
};

export default function Page({ pageData }: any) {
  return (
    <LocalizationProvider localizationLookup={(pageData?.page as any)?.localizationLookup}>
      <ContentModuleProvider contentMapping={contentMapping}>
        <ContentModule {...pageData?.page} />
      </ContentModuleProvider>
    </LocalizationProvider>
  );
}
